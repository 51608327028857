<template>
  <div id="app-layout">           
    <div class="home__title">
              <h2 class="home__title__bg">GUIDES PRATIQUES</h2>
            </div>         
            <div class="pagecontent">
              <div class="guide__container">
                  <div class="guide">
                    <div class="guide__img__container"><img class="guide__img" src="../assets/succession.webp"></div>
                    <div class="guide__block">
                      <h3 class="guide__title">Guide à la succession</h3>
                      <p class="guide__text">Qui hérite ? Dans quelle proportion ? Quel en est le coût ?</p>
                      <a class="guide__link" href="./pdf/CSN_Succession.pdf" download><i class="far fa-file-pdf"></i> Consulter le guide</a>
                    </div>
                  </div>
                  <div class="guide">
                    <div class="guide__img__container"><img class="guide__img" src="../assets/heritage.webp"></div>
                    <div class="guide__block">
                      <h3 class="guide__title">Guide à l'héritage</h3>
                      <p class="guide__text">Des funérailles à l’ouverture de la succession : l’essentiel à retenir.</p>
                      <a class="guide__link" href="./pdf/Guide_héritage_2020_WEB.pdf" download><i class="far fa-file-pdf"></i> Consulter le guide</a>
                    </div>
                  </div>
                  <div class="guide">
                    <div class="guide__img__container"><img class="guide__img" src="../assets/vendre.webp"></div>
                    <div class="guide__block">
                      <h3 class="guide__title">Guide à l'achat immobilier</h3>
                      <p class="guide__text">Achat immobilier : mode d'emploi.</p>
                      <a class="guide__link" href="./pdf/Guide_Vente_immobiliere-01072016.pdf" download><i class="far fa-file-pdf"></i> Consulter le guide</a>
                    </div>
                  </div>
                  <div class="guide">
                    <div class="guide__img__container"><img class="guide__img" src="../assets/achat.webp"></div>
                    <div class="guide__block">
                      <h3 class="guide__title">Guide à la vente immobilière</h3>
                      <p class="guide__text">Vente immobilière : mode d'emploi.</p>
                      <a class="guide__link" href="./pdf/Guide_Achat_immobilier-01072016.pdf" download><i class="far fa-file-pdf"></i> Consulter le guide</a>
                    </div>
                  </div>
            </div>
            </div>
  </div>
</template>

<script>
  export default {
    name: "missions"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.guide {
    display: flex;
    padding-bottom: 15px;
    margin-top: 15px;
    border-bottom: 2px solid $tertiary-color;
    animation: guides 1000ms;
    animation-fill-mode: backwards;
    &__img {
        width: 100%;
        height: auto!important;
        object-fit: cover;
        border-radius: 3px;
        &__container {
            display: block;
            width: 35%;
            height: auto!important;
            margin-right: 20px;
            max-width: 250px;
            min-width: 50px;
        }
    }
    &__block {
        position: relative;
        width: 100%;
    }
    &__link {
        display: block;
        position: absolute;
        right: 0;
        bottom: 5%;
        font-size: x-large;
        border: 1px solid black;
        border-radius: 3px;
        padding: 5px;
        box-shadow: 0.5px 0.5px 0px 0px $tertiary-color;
        transition: all 250ms;
        &:hover {
            background-color: lighten($color: $tertiary-color, $amount: 15);
        }
        @media screen and (max-width: 950px){
            position: initial;
            font-size: inherit;
            text-align: center;
            } 
    }
    &__text {
        font-size: small;
    }
    &__title {
        margin: 0;
    }
}

@keyframes guides {
    0% {
        transform: translateY(10px);
        opacity: 0;
  }
      
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@for $i from 1 through 9 {
    .guide:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.25}s; }
 }
</style>